/** *   IMAGE SETTER 🌉   ***/

/*
  Create set of images to use in <img> srcset, and images with reduced size

  widthArr => array used to define srcset with different width

  imageQueryParams => set default parameters and add custom parameters (such as 'width',
  'quality' etc) into query parameters ('w', 'q' etc).

  imageUrl => set the url domain and the image query parameters (specify the height, crop the image...)

  srcsetDensity => creates srcset with densities 1, 2 and 3, with the specified parameters

  srcsetWidth => creates srcset with the different width specified by widthArr

  ********************
  ** NOTE ON SRCSET **

  SRCSET WITH WIDTH AND SIZES

  https://ericportis.com/posts/2014/srcset-sizes/

  One way of using srcset is to provide different images and specify their width. You should
  use the 'sizes' attribute to tell the browser how much space does the image take compared to the
  viewport size.
  When using srcset with sizes, the browser will pick the right image width from the srcset
  based on calculation:

    rendered width × screen density > next-smallest-file’s width

  That is to say, given the width of the image on your page (provided by the 'sizes' attribute)
  and the screen density (1 for standard screen, 2 for retina, 3 for recent smartphones), the
  browser will pick the image width right above the rendered width.
  For example, if your image is 590px wide on your screen, if the srcset contains images with
  width of {500px, 600px, 700px...}, the browser will pick the 600px-wide image.

  SRCSET WITH DENSITY

  The other way to use srcset is to provide images and their corresponding density.
  1x is the default image, 2x is twice as large, etc.
  If we use srcset with density, we can't use 'sizes' (since we're not providing the image's width).
  The browser will look at the device's pixel ratio and pick an image accordingly.

  ********************

*/

const imageQueryParams = (params) => {
  const queryParams = {}
  const defaultParams = {
    q: 65,
  }
  const corresponding = {
    width: 'w',
    height: 'h',
    resize: 'func',
    quality: 'q',
    blur: 'blur',
  }
  let queryString = ''

  for (const param in params) {
    if (corresponding[param]) {
      queryParams[corresponding[param]] = params[param]
    }
  }

  if (params.aspectRatio) {
    // the aspect ratio of an image is the width divided by height
    if (params.width) {
      queryParams.h = Math.round(params.width / params.aspectRatio)
    }

    if (params.height) {
      queryParams.w = Math.round(params.height * params.aspectRatio)
    }
  }

  if (params.density) {
    if (queryParams.w) {
      queryParams.w = Math.round(queryParams.w * params.density)
    }
    if (queryParams.h) {
      queryParams.h = Math.round(queryParams.h * params.density)
    }
  }

  const finalParams = Object.assign({}, defaultParams, queryParams)

  queryString = Object.keys(finalParams)
    .map((param) => `${param}=${finalParams[param]}`)
    .join('&')

  return queryString
}

export const imageUrl = (path, params = {}) => {
  const config = useRuntimeConfig()
  // Check if url contains cdn.lecollectionist
  if (path) {
    const cdnRegex = /(cdn.lecollectionist)/
    const cdnUrl = config.public.imgCdnDomain
    const isFileStackUplaodedImage = path.search(cdnRegex) > 0
    const baseUrl = isFileStackUplaodedImage
      ? `${path}?${imageQueryParams(params)}`
      : `https://${cdnUrl}/${path}?${imageQueryParams(params)}`

    return baseUrl
  }

  return ''
}

export const srcsetDensity = (image, params = {}) => {
  const res = []
  const densityParams = {
    1: {},
    2: { density: 2, quality: 50 },
  }

  for (const density in densityParams) {
    const densityParam = Object.assign({}, params, densityParams[density])
    const imgUrl = imageUrl(image, densityParam)

    res.push(`${imgUrl} ${density}x`)
  }

  return res.join(', ')
}

export const srcsetWidth = (image, params = {}) => {
  const widthArr = [300, 600, 900, 1200, 1500, 1800, 2100, 2400]

  return widthArr
    .map((width) => {
      const widthParams = Object.assign({}, params, { width })
      const imgUrl = imageUrl(image, widthParams)

      return `${imgUrl} ${width}w`
    })
    .join(', ')
}
